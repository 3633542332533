<template>
  <m-select
    class="analysis__year"
    :value="value"
    :items="years"
    placeholder="2024"
    :clearable="false"
    suffix="год"
    :style="'min-width: 140px; width: 140px;'"
    @input="$emit('input', $event)"
    v-bind="$attrs"
  ></m-select>
</template>
<script>
export default {
  components: { mSelect: () => import("@/components/base/inputs/mSelect") },
  props: {
    value: {
      type: Number,
      default: 2024,
    },
  },
  data() {
    return {
      years: [],
    };
  },
  mounted() {
    this.years = [];
    for (let year = 2015; year <= new Date().getFullYear(); year++)
      this.years.push(year);
  },
};
</script>
<style lang="scss">
.analysis__year {
  padding-top: 0;
  .v-select__slot {
    font-weight: 500;

    .v-select__selection {
      height: 25px;
      font-size: 27px;
      color: var(--v-primary-base);
      margin: 0;
      line-height: 100%;
    }
    .v-text-field__suffix {
      font-size: 20px;
      color: var(--v-primary-base);
    }
  }
}
</style>
